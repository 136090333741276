import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import Routes from "./Routes";
import "bootstrap/dist/css/bootstrap.css";
import * as Sentry from "@sentry/browser";
import "./App.css";
import { LicenseInfo } from '@mui/x-license-pro';

class App extends Component {
  constructor(props) {
    LicenseInfo.setLicenseKey('8afd3fff638bc1aec6645dcfc2d86095Tz0xMDIwOTEsRT0xNzYzNjc0MTkzMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');
    super(props);
    
    Sentry.init({
      dsn: "https://86561c267c004aef9b32e24f74cfb305@o281205.ingest.sentry.io/5192635",
      environment: process.env.NODE_ENV,
      tracesSampleRate: process.env.NODE_ENV === "development" ? 0 : 0.7,
    });
  }

  render() {
    return <Routes />;
  }
}

export default withRouter(App);
